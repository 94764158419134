body {
  font-family: 'Work Sans', sans-serif;
}

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.modify-nav nav.navbar {
  display: block;
}

.modify-nav nav.navbar .navbar-brand {
  display: flex;
  position: relative;
  z-index: 9;
}
.navbar-brand p{
 cursor: pointer;
}
.active-link{
  color:#C1D9C3 !important;
}
.section-home {
  padding: 1rem 1.5rem;
}

.logo {
  margin-right: 7px;
}

.main-menu a:focus {
  box-shadow: none !important;
}

.main-menu a:hover {
  text-decoration: none !important;
}

.container-padding {
  padding: 2rem !important;
}
.main-heading {
  font-size: 46px!important;
  line-height: 1.2;
}

.card-data p {
  position: absolute;
  right: 25px;
  top: 16px;
  color: white;
  font-weight: 800;
}

.card-data img {
  object-fit: cover;
}

.section-cards {
  padding: 1rem 0rem;
}

.section-cards .container {
  padding: 1rem;
  background: #C1D9C3;
}

.section-cards .card {
  cursor: pointer;
  border: none;
  border-radius: 0px;
}

.column.is-one-third:first-child {
  position: relative;
}

.more-button {
  position: absolute;
  bottom: 0;
  padding-bottom: 30px;
}

.content-div {
  margin: auto;
}

.more-button button {
  background: transparent;
  border: 1px solid #233f25;
  border-radius: 0;
  padding: 0px 30px;
}

.accordion .card {
  box-shadow: none !important;
  border-right: none;
  border-left: none;
} 

.card-header.active-row button.btn {
  background: rgb(250, 250, 250) !important;
}

.card-header.active-row button.btn.collapsed {
  background-color: transparent!important;
}

.accordion .card-header {
  border-bottom: none !important;
}

.accordion .card div#headingOne {
  background: none;
  padding:0px;
}

.accordion .card div#headingTwo {
  background: none;
  padding: 0px;
}

.accordion .btn {
  padding: 24px 16px;
  display: flex;
  justify-content: space-between;
}

.accordion .btn:focus {
  box-shadow: none;
  text-decoration: none;
}

.accordion .btn:hover {
  text-decoration: none;
}

.accordion-content {
  border-bottom: 1px solid #c5c2bb;
  padding: 0px;
}

.card-header .btn {
  color: black;
}

.accordion-footer {
  display: flex;
  align-content: center;
  align-items: center;
}

.accordion-footer p {
  margin: 0;
  padding: 16px 6px;
  color: black;
}

.accordion-card-footer {
  padding: 40px 24px;
}

.open-content {
  background: rgb(250, 250, 250);
}
/* .card-hover {
  display: none;
} */

.card-Listing-section {
  overflow: auto;
}

/* /* .card:hover  */
.card-hover { 
  background: rgba(0, 0, 0, 0.65);
  position: absolute;
  top: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 10px 25px;
  color: white;
  text-align: left;
}

.image.is-4by3 {
  padding-top: 100%;
}

.card-hover span {
  position: absolute;
  right: 25px;
  top: 16px;
  color: white;
  font-weight: 800;
}

.card-hover p {
  margin-bottom: 10px;
  line-height: 1.4;
}

.accordion .card-body {
  text-align: left;
}

.down-icon {
  display: flex;
  align-items: center;
}

.card-image figure {
  margin: 0px;
}

.section-bottom {
  background: #d6cde2;
  margin-top: 80px;
  padding: 20px 0px 50px;
}

.section-bottom .card {
  height: 100%;
}

.bottom-heading {
  line-height: 1.4;
  display: block;
  margin: auto;
  max-width: 513px;
  width: 100%;
}

.bottom-heading p:first-child {
 font-size: 46px;
 font-weight: bolder;
 margin-top: 55px;
}

.bottom-heading p:last-child {
  font-size: 17px;
  margin-bottom: 40px;
}


.title-div {
  border-bottom: 1px solid lightgrey;
  padding: 5px 22px 30px;
  min-height: 125px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}

.title-div p{
  margin-bottom: 0px;
}

.subtitle {
  padding: 0px 21px 30px 21px !important;
  margin-bottom: 0px;
}

.section-bottom .columns.is-multiline {
  margin-top: 35px;
}

.footer-div {
  margin: 120px 0px 0;
  padding: 40px 35px 20px;
  background-color: #f5f6f8;  
}
.footer-div.show-footer-section{
  padding-bottom: 100px !important;
}
.footer-div .footer-copyright {
  display: flex;
  justify-content: space-between;
}

.modify-nav nav.navbar .navbar-brand {
  margin-right: 0 !important;
}

.navbar {
  padding: 0;
}

.navbar-start p {
  margin-bottom: 0px !important;
}

.navbar-start p, .footer-item{
  padding: 12px 30px;
  color: black;
  font-weight: 500;
  border: none;
  border-radius: 0;
}

.navbar-start p:hover, .footer-item:hover {
  text-decoration: none;
  color: black;
}

/* .navbar-start p:last-child, .footer-item:last-child {
  border-left: 2px solid black;
  padding-right: 0px;
} */

.footer-item:first-child {
  padding-right: 0px;
}

.footer-div .level {
  margin-bottom: .5rem !important;
}

.card:first-child {
  border-radius: 0 !important;
}

.card:last-child {
  border-radius: 0 !important;
}

.navbar-burger span:nth-child(2) {
  margin: 0 !important;
  display: none;
}

.navbar-burger span {
  height: 3px;
  width: 24px;
}

.navbar-burger:hover {
  background: none !important;
}

.card-size {
  min-width: 300px;
}

.info-heading {
  font-size: 17px;
}

#navbarExampleTransparentExample {
  position: absolute;
  width: 100%;
  box-shadow: none;
}

.navbar-start .register{
    border: solid 2px #C1D9C3;
    color: #212529!important;
    padding: 8px 30px!important;
    border-radius: 6px;
    margin-left: 25px;
    text-decoration: none;
}
.footer_copyright_area{
  text-align: right;
}
.navbar-start .navbar-item a{
  text-decoration: none;
  color: #212529;

}
/* infographic */
.hero-text h1 {
  font-weight: bolder;
  line-height: 2;
}
.membership-items {
  padding-top: 43px;
  display: flex;
  justify-content: center;
}
.membership-items h5{
  font-size: 18px;
  margin: 12px 0 0;
  line-height: 30px;
  color: #292c4e;
  font-weight: 600;
}

.membership-item-box{
  max-width: 150px;
  width: 100%;
  text-align: center;
  margin: 0 15px;
}
p.subheader-size {
  font-size: 17px;
  font-weight: 500;
  max-width: 700px;
  margin: 0 auto;
  margin-bottom: 20px;
}

.hero-image img {
  width: 750px;
}
.benefits-items {
  padding-top: 43px;
  display: flex;
  justify-content: center;
}
.benefits-item-box{
  /* max-width: 150px; */
  width: 100%;
  text-align: center;
  margin: 0 15px;
  background: #f5f5f5;
  border-radius: 20px;
}
.benefits-item-box h5{
  font-size: 18px;
  margin: 12px 0 0;
  line-height: 30px;
  color: #292c4e;
  font-weight: 600;
}
.benefits-item-box p{
  padding: 0 10px;
}
/* our-members-section */
.our-members-section {
  padding: 40px 0;
}

.section-title {
  position: relative;
}

.top-text-details {
  margin-bottom: 60px;
}

.section-title:after {
  content: '';
  position: absolute;
  top: 100%;
  left: 50%;
  -webkit-transform: translateX(-50%);
  -moz-transform: translateX(-50%);
  -o-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  width: 100%;
  max-width: 272px;
  height: 2px;
  background-color: #3d3d3d;
}

.section-title {
  margin-bottom: 30px;
}

.section-title h2 {
  line-height: 2;
  font-weight: bolder;
}

.our-members-section .section-content .wider-text {
  max-width: 700px;
  margin: -10px auto 20px;
  text-align: center;
  font-size: 17px;
}

.our-members-section .types-of-industries-list {
  width: 100%;
  max-width: fit-content;
  margin: 0px auto;
  list-style: none;
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
}

.our-members-section .types-of-industries-list li {
  padding-left: 40px;
  position: relative;
}

.our-members-section .types-of-industries-list li {
  padding-left: 40px;
  position: relative;
}
.our-members-section .types-of-industries-list li.has-span-icon span {
  width: 232px;
  text-align: left;
  padding-left: 40px;
  position: relative;
  margin-left: auto;
  display: block;
}

.our-members-section .types-of-industries-list li.has-span-icon span:before {
  font-family: "FontAwesome";
  font-weight: 900;
  content: '\f00c';
  position: absolute;
  top: -5px;
  left: 0px;
  color: #C1D9C3;
}

/* basic-membership-section */
.basic-membership-section {
  background-color: #C1D9C3;
}

.membership-plans {
  margin: 0 auto;
  text-align: center;
  width: 80%;
  background: #fff;

  box-sizing: border-box;
  border-radius: 0;
  margin-left: 10%;
  position: relative;
}

.left-side-text p {
  font-size: 1.25rem;
  color: #212529;
  text-align: left;
  font-weight: 600;
}

.right-side-text h3 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 30px;
}

a.btn-class {
  padding: 15px 17px;
  border: solid 2px #fff;
  color: #fff;
  padding: 8px 30px !important;
  border-radius: 6px;
  font-size: 22px;
  text-decoration: none;
}

.membership-plans .nav.nav-tabs {
  background-color: #c1d9c357;
  border-radius: 100px;
  color: #292c4e;
  display: flex;
  width: fit-content;
  margin: 0 auto;
  border: none;
}

li.nav-item {
  border-radius: 100px;
  border-radius: 100px;
  overflow: hidden;
  border: none;
}

.membership-plans .nav-tabs .nav-link {
  border: none;
}

li.nav-item a {
  background: transparent;
  min-width: 150px;
  color: #212529;
  cursor: pointer;
  padding: 15px 30px;
}

li.nav-item .nav-link.active {
  background-color: #C1D9C3;
}

.membership-wrp {
  padding: 40px 15px;
}

.tab-pane {
  margin-top: 20px;
}

.tab-pane {
  margin-top: 20px;
}

.member-plan-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #C1D9C3;
  padding: 20px 0;
}

.member-plan-icon {
  width: 65px;
  height: 65px;
  overflow: hidden;
}

.member-plan-icon img {
  object-fit: contain;
}

.row-left {
  display: flex;
  align-items: center;
  gap: 20px;
}

.member-plan-text p {
  margin: 0;
}

.member-plan-price {
  background-color: #C1D9C3;
  font-size: 1rem;
  border-radius: 100px;
  padding: 15px 20px;
  min-width: 94px;
}
.member-plan-text {
  text-align: left;
}
.member-plan-text h5 {
  font-weight: 600;
}

.member-plan-details .btn-class{
  border-color: #C1D9C3;
    display: block;
    width: fit-content;
    margin: 0 auto;
    margin-top: 30px;
    font-weight: 600;
    color: #212529;
}

.membership-benefit-item {
  display: flex;
  justify-content: flex-start;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 30%;
  margin-right: 2%;
  margin-bottom: 50px;
  background-color: #fff;
  border-radius: 10px;
  padding: 15px 25px;
  position: relative;
}
.memberships-benefits {
  display: flex;
  justify-content: center;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
}

.second-section-title h2 {
  padding-bottom: 35px;
  font-weight: 600;
}
.membership-benefit-item h4 {
  font-weight: 600;
  color: #212529;
}

.membership-benefit-item p {
  line-height: 1.4;
  font-size: 16px;
}
.benefits-resources-overview{
  padding-top: 40px;
}
p.section-subtitle {
  max-width: 700px;
  margin: 0px auto 50px;
  text-align: center;
}

.benefits-bottom-button{
  margin-top: 60px;
}
.benefits-btn-class{
  border: solid 2px #fff;
  color: #212529;
  background-color: #C1D9C3;
  padding: 15px 30px !important;
  border-radius: 100px;
  font-size: 18px;
  text-decoration: none;
}
.benefits-btn-class:hover{
  text-decoration: none;
  color:#212529 ;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}
.footer-close-icon {
  display: flex;
  align-items: end;
  justify-content: end;
  margin-bottom: 4px;
  padding: 20px 60px 10px;
}

.footer-close-icon img {
  width: 15px;
  cursor: pointer;
}
.footer-fixed-content {
  padding: 0px 45px 24px;
  color: #212529;
}
.footer-fixed-content a {
  text-decoration: underline !important;
  color: #212529;
}

.footer-main-container{
  position: relative;
}
.footer-fixed-section{
  background: #f5f6f8!important;
  line-height: 20px;
  position: fixed;
  bottom: 0px;
  z-index: 999;
  margin-left: -30px;
  margin-right: -30px;
}

@media only screen and (min-width: 1410px){
  .info-heading {
    font-size: 1.25rem;
  }
}

@media only screen and (min-width: 1024px){
  .modify-nav nav.navbar {
    display: flex;
  }
  
  .modify-nav nav.navbar .navbar-brand {
    display: inline-block;
  }

  #navbarExampleTransparentExample {
    justify-content: flex-end;
  }

  .navbar-start {
    margin-right: 0;
  }

  .navbar-start .navbar-item {
    text-align: left;
  }
  
} 

@media only screen and (max-width: 1200px) {
  .main-heading {
    font-size: 2.4rem !important;
    line-height: 1.2;
  }

  .info-heading {
    font-size: 15px;
    margin-bottom: 45px;
  }

  .more-button .btn {
    width: 150px;
    height: 47px;
    padding: 16px 37px 16px 37px;
    border: solid 1px #233f25;
  }

}

@media only screen and (max-width: 1024px) {

  .navbar-start p:last-child,
 .footer-item:last-child {
    border-left: none !important;
    padding-right: 0px;
  }

  .navbar-start {
    text-align: left;
  }

  .column.is-one-third.accordion-footer {
    width: 50%;
    float: left;
  }

  .navbar-start .register{
    padding: 7px 15px!important;
    margin: auto;
    margin-top: 10px!important;
    border: solid 2px #C1D9C3;
    color: #212529!important;
    border-radius: 6px;
    width: 30%;
    text-align: center;
  }
}

@media only screen and (max-width: 993px) {

  .image-cards .column {
    display: block;
    width: 100%;
  }
  .membership-benefit-item{
    width: 45%;
  }
}

@media only screen and (max-width: 768px){

  .info-heading {
    font-size: 18px !important;
    margin-bottom: 45px !important;
  }

  .card-hover p {
    font-size: 20px !important;
  }

  .card-hover {
    padding: 40px !important;
  }

  .card-hover span {
    font-size: 2.5rem !important;
  }

  .card-size {
    width: 50%;
    float: left;
  }

  .card {
    border: 0px !important;
  }

  .navbar-start .navbar-item {
    font-size: 20px !important;
  }

  .navbar-start p:last-child, .footer-item:last-child {
    border-left: none !important;
    padding-right: 0px;
    padding-bottom: 30px;
  }

  .bottom-heading p:first-child {
    margin-top: 10px;
  }

  .footer-div .level-right .level-item p:last-child {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }
  
  .footer-div .level-right .level-item p:nth-child(2) {
    padding-right: 0px !important;
  }

  .footer-div .footer-copyright span, .footer-div .footer-copyright p {
    text-align: center !important;
  }

  .footer-div .footer-copyright {
    display: block;
  }

  .footer-address {
    text-align: center !important;
  }

  .footer-div .level-right .level-item p:first-child {
    padding-left: 0px !important;
  }
  .footer_copyright_area{
    text-align: center;
  }
  .left-side-text p{
    text-align: center;
    font-size: 17px;
  }
  .membership-benefit-item{
    width: 45%;
    flex-wrap: wrap;
  }
  .right-side-text h3{
    font-size: 22px;
  }
  .membership-benefit-item h4{
    font-size: 19px;
  }
  .footer-div.show-footer-section {
    padding-bottom: 200px !important;
  }
}

@media screen and (max-width: 767px){
  .footer_copyright_area{
    text-align: center;
  }
  .main-heading {
    font-size: 2.4rem!important;
    line-height: 1.2;
    margin-top: 6px;
  }

  .info-heading {
    font-size: 15px !important;
    margin-bottom: 45px !important;
  }

  .more-button .btn {
    width: 150px;
    height: 47px;
    padding: 16px 37px 16px 37px;
    border: solid 1px #233f25;
  }

  .bottom-heading {
    max-width: 368px;
  }

  .navbar-start .navbar-item {
    font-size: 20px !important;
  }

  /* .card:hover  */
  .card-hover {
    background: rgba(0, 0, 0, 0.65);
    position: absolute;
    top: 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    color: white;
    text-align: left;
    font-size: 14px;
    padding: 0px 16px 20px !important;
  }

  .card-hover span {
    font-size: 1.6rem !important;
  }

  .card-hover p {
    width: 100%;
    height: auto;
    font-family: WorkSans;
    font-size: .85rem !important;
    font-weight: 300;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.5;
    letter-spacing: normal;
    text-align: left;
    color: #ffffff;
    margin-bottom: 0px;
  }

  .section-home {
    padding: 0rem !important;
  }

  .container-padding {
    padding: 1rem !important;
  }

  .bottom-heading p:first-child {
    margin: 40px 15px 16px 0 !important;
    font-size: 2rem !important;
  }

  .accordion-content p {
    font-size: 15px !important;
  }

  div#accordionExample {
    padding-bottom: 0px;
  }

  .accordion-content p:last-child{
    margin-bottom: 35px;
  }

  .accordion-card-footer {
    padding: 20px 6px;
  }

  .accordion-card-footer .accordion-footer {
    padding-left: 0px;
  }

  #accordionExample .card:last-child {
    border-bottom : none;
  }

  #accordionExampleTwo {
    padding-top: 0;
  }

  .accordion .btn {
    padding: 24px 16px;
  }

  .bottom-heading p:last-child {
    margin: 16px 21px 48px !important;
    font-size: 1rem !important;
  }

  .scroll-card {
    overflow-x: scroll;
    display: flex;
    width: 100%;
  }

  .section-bottom .card {
      min-width: 285px;
      margin-right: 16px;
  }

  .column.is-one-third.accordion-footer {
    width: 50%;
    float: left;
    padding-left: 8px;
  }

  .footer-div {
    margin: 60px 0px 0px!important;
  }

  .footer-div .level-right .level-item p:last-child {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
  }

  .footer-div .footer-copyright {
    display: block;
  }
  
  .footer-div .footer-copyright span , .footer-div .footer-copyright p{
    text-align: center !important;
  }

  .footer-address {
    text-align: center !important;
  }

  .footer-item:first-child {
    padding-left: 0px !important;
  }

  .navbar-start p:last-child, .footer-item:last-child {
    border-left: none !important;
    padding-right: 0px;
    padding-left: 0px;
    padding-bottom: 30px !important;
  }

  .footer-div {
    padding: 32px 16px 40px 15px;
  }

  .footer-div .footer-item {
    font-size: 14px !important;
  }

  .footer-div .level-right .level-item {
    justify-content: space-between !important;
    padding-right: 4px;
    padding-left: 4px;
  } 

  .navbar-start p {
    padding: 16px 15px 10px !important;
    background: transparent;
    font-size: 20px !important;
    text-align: left;
  }

  .footer-div .level-right .level-item p:nth-child(2) {
    padding-right: 40px !important;
  }
  .navbar-start .register{
    padding: 5px 15px!important;
    margin: auto;
    margin-top: 10px!important;
    border: solid 2px #C1D9C3;
    color: #212529!important;
    border-radius: 6px;
    width: 45%;
    text-align: center;
    font-size: 1rem !important;
  }
  .hero-text h1, .section-title h2, .second-section-title h2{
    font-size: 29px;
  }
  
  li.nav-item a{
    padding: 8px 20px;
    min-width: 90px;
  }
  .member-plan-text h5{
    font-size: 18px;
    margin: 0;
  }
  .member-plan-price{
    padding: 4px 12px;
    font-size: 15px;
  }
  .member-plan-icon{
    width: 55px;
    height: 55px;
  }
  .member-plan-details .btn-class{
    font-size: 18px;
  }
}



@media screen and (max-width: 575px) {
  .membership-benefit-item{
    width: 100%;
    margin-bottom: 15px;
  }
  .membership-plans{
    width: 100%;
    margin-left: 0;
  }
  .bottom-button {
    margin-top: 15px;
  }
  .our-members-section .types-of-industries-list li{
    padding-left: 0;
  }
  .our-members-section .types-of-industries-list li.has-span-icon span{
    width: auto;
    padding-left: 30px;
  }
  .hero-text h1{
    line-height: normal;
  }
  .section-title:after{
    max-width: 204px;
  }

  .membership-items{
    flex-direction: row;
    flex-wrap: wrap;
  }
  .membership-item-box{
    min-width: 150px;
    margin: 0;
  }
  .membership-items h5{
    margin: 0;
  }
  .benefits-item-box{
     margin: 0;
     margin-bottom: 20px;
  }
  .benefits-items{
    flex-direction: column;
  } 
}
@media screen and (max-width: 320px){
  li.nav-item a{
    padding: 8px 11px;
    min-width: 67px;
    font-size: 12px;
  }
  .member-plan-icon{
    width: 40px;
    height: 40px;
  }
  .row-left{
    gap: 10px;
  }
  .member-plan-text h5{
    font-size: 15px;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
